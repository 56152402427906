import React, { useRef } from "react";
import { baseUrl } from "../index";
import axios from "axios";

import {
  Button,
  Card,
  CardBody,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  Alert,
} from "reactstrap";
import Moment from "react-moment";
import randomColor from "randomcolor";
import { connect } from "react-redux";
import { bookedHostel } from "../Redux/booking";
import Slider from "react-slick";

// Components
import Footer from "../Components/Footer";
import withRouter from "../Components/withRouter";

// Styles and assets
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/HostelPage.css";
import HostelRoom from "../Assets/HostelRoom.png";
import typeImg from "./assets/type.png";
import characterImg from "./assets/character.png";
import socialImg from "./assets/social.png";
import diversityImg from "./assets/diversity.png";
import bedImg from "./assets/bed.png";
import dormImg from "./assets/dorm.png";
import catImg from "./assets/cat.png";
import dogImg from "./assets/dog.png";
import checkinImg from "./assets/checkin.png";
import checkoutImg from "./assets/checkout.png";
import alcoholImg from "./assets/alcohol.png";
import smokingImg from "./assets/smoking.png";
import ageImg from "./assets/age.png";
import petsImg from "./assets/pets.png";
import hostelfacilitiesImg from "./assets/hostelfacilities.png";
import ExperiencesCarousel from "../Components/ExperiencesCarousel"
import "../index.css"
import "../App.css"
import Beach from "../Assets/slider-preview-images/grily.jpg"
import Flying from "../Assets/slider-preview-images/ice.jpeg"
import Trek from "../Assets/slider-preview-images/trek.jpg"
import Friends from "../Assets/slider-preview-images/friends.jpeg"

const smallDeviceSlider = {
  infinite: true,
  slidesToShow: 3,
  speed: 500,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};
class HostelPage extends React.Component {
  constructor() {
    super();
    this.state = {
      checkInDate: "",
      checkOutDate: "",
      hostelId: 0,
      days: 0,
      hostel: [],
      totalAmount: 0,
      inventory: [],
      allImages: [],
      isModal: false,
      bookingData: {},
      activePlaceOffersTab: "Vibes",
        allHostels: [
          {
            id: 1,
            location: "Goa",
            thumbnail: Beach,
            description: "A cozy hostel near the Eiffel Tower, great for backpackers.",
          },
          {
            id: 2,
            location: "Anjuna",
            thumbnail:Trek,
            description: "Experience the heart of Shibuya at this modern hostel.",
          },
          {
            id: 3,
            location: "Himachal",
            thumbnail: Flying,
            description: "A vibrant hostel located near Times Square.",
          },
          {
            id: 4,
            location: "jawai",
            thumbnail: Friends,
            description: "A vibrant hostel located near Times Square.",
          },
        ],    
        reviews : [
          {
            name: "Jannike Borg",
            role: "Publisher",
            review:
              "I quickly found the right tour for me, but I had a few questions about the hotel, I wrote to tech support and they answered all my questions within an hour. The vacation itself was perfect. Thank you very much. I will come back again and again.",
            avatar: "path-to-avatar-image", 
          },
          {
            name: "LeBron Durant",
            role: "Flight attendant",
            review:
              "I quickly found the right tour for me, but I had a few questions about the hotel, I wrote to tech support and they answered all my questions within an hour. The vacation itself was perfect. Thank you very much. I will come back again and again.",
            avatar: "path-to-avatar-image", 
          },
          {
            name: "Kaarel Piho",
            role: "Chiropodist",
            review:
              "I quickly found the right tour for me, but I had a few questions about the hotel, I wrote to tech support and they answered all my questions within an hour. The vacation itself was perfect. Thank you very much. I will come back again and again.",
            avatar: "path-to-avatar-image", 
          },
        ]

    };
    this.experiencesRef = React.createRef();
    this.aboutRef = React.createRef();
    this.roomRef = React.createRef();
    this.facilitiesRef = React.createRef();
    this.reviewsRef = React.createRef();
    this.sliderRef = React.createRef();

  }
  // async componentDidMount() {
  //   const { searchParams } = this.props.router;
  //   this.setState(
  //     {
  //       hostelId: searchParams.get("hostel"),
  //       checkInDate: searchParams.get("from"),
  //       checkOutDate: searchParams.get("to"),
  //     },
  //     () => {
  //       const d1 = new Date(this.state.checkInDate);
  //       const d2 = new Date(this.state.checkOutDate);
  //       const timeDifference = Math.abs(d2 - d1);
  //       const differenceInDays = Math.ceil(
  //         timeDifference / (1000 * 60 * 60 * 24)
  //       );
  //       this.setState({ days: differenceInDays });
  //     }
  //   );
  //   axios
  //     .get(`${baseUrl}/hostel/hostel/${searchParams.get("hostel")}`)
  //     .then((response) => {
  //       this.setState({ hostel: response.data });
  //     });
  //   axios
  //     .get(
  //       `${baseUrl}/inventory/hostel-inventory/${searchParams.get("hostel")}`
  //     )
  //     .then((response) => {
  //       const fromDate = new Date(searchParams.get("from"));
  //       const toDate = new Date(searchParams.get("to"));
  //       const filteredData = response.data.filter((item) => {
  //         const itemFromDate = new Date(item.fromDate);
  //         return (
  //           itemFromDate < toDate &&
  //           itemFromDate >= fromDate &&
  //           item.availableRooms > item.bookedRooms
  //         );
  //       });
  //       console.log(filteredData)
  //       const groupedData = Array.from(
  //         filteredData.reduce((map, item) => {
  //           const { roomType, cost, availableRooms, bookedRooms, vendorDiscount,...rest } =
  //             item;
  //           if (map.has(roomType)) {
  //             const existing = map.get(roomType);
  //             existing.cost += cost;
  //             existing.vendorDiscount +=vendorDiscount

  //             existing.availableRooms = Math.min(
  //               existing.availableRooms,
  //               availableRooms
  //             );
  //             existing.bookedRooms += bookedRooms;
  //           } else {
  //             map.set(roomType, {
  //               roomType,
  //               cost,
  //               availableRooms,
  //               bookedRooms,
  //               vendorDiscount,
  //               ...rest,
  //             });
  //           }
  //           return map;
  //         }, new Map())
  //       ).map(([_, value]) => value);
  //       this.setState({ inventory: groupedData });
  //     });
  //   axios
  //     .get(
  //       `${baseUrl}/inventory/hostel-images/cloudinary?folder=${searchParams.get(
  //         "hostel"
  //       )}`
  //     )
  //     .then((response) => {
  //       this.setState({ allImages: response.data });
  //     });
  // }
  async componentDidMount() {
    const { searchParams } = this.props.router;
    try {
      // Set initial state
      this.setState(
        {
          hostelId: searchParams.get("hostel"),
          checkInDate: searchParams.get("from"),
          checkOutDate: searchParams.get("to"),
        },
        () => {
          const d1 = new Date(this.state.checkInDate);
          const d2 = new Date(this.state.checkOutDate);
          const timeDifference = Math.abs(d2 - d1);
          const differenceInDays = Math.ceil(
            timeDifference / (1000 * 60 * 60 * 24)
          );
          this.setState({ days: differenceInDays });
        }
      );
  
      // Fetch hostel data
      const hostelResponse = await axios.get(
        `${baseUrl}/hostel/hostel/${searchParams.get("hostel")}`
      );
      this.setState({ hostel: hostelResponse.data });

      // Fetch inventory data
      const inventoryResponse = await axios.get(
        `${baseUrl}/inventory/hostel-inventory/${searchParams.get("hostel")}`
      );
      const fromDate = new Date(searchParams.get("from"));
      const toDate = new Date(searchParams.get("to"));
      let filteredData = inventoryResponse.data.filter((item) => {
        const itemFromDate = new Date(item.fromDate);
        return (
          itemFromDate < toDate &&
          itemFromDate >= fromDate &&
          item.availableRooms > item.bookedRooms
        );
      });
//filtering rooms on the basis of availability in date range  on room type level 
      const filterRooms = () => {
        let roomTypeMap = new Map();
        // counting same roomtype of all days 
        for(let i = 0 ;i<filteredData.length;i++){
          const item = filteredData[i]
          const roomType = item.roomType;
          if (roomTypeMap.has(roomType)) {
            roomTypeMap.set(roomType, roomTypeMap.get(roomType) + 1);
          } else {
            roomTypeMap.set(roomType, 1); 
          }
        }
        // filtering.. if roomtype counting is less than selected date difference then removing all rooms of that type 
       const filterType =  filteredData.filter((item) =>{
          const roomType = item.roomType
          const availableForDays = roomTypeMap.get(roomType)
        return  availableForDays >= this.state.days;
        })
      return  filteredData = filterType 
        };
filterRooms()
console.log(filteredData)
      const groupedData = Array.from(
        filteredData.reduce((map, item) => {
          const { roomType, cost, availableRooms, bookedRooms, vendorDiscount, ...rest } =
            item;
          if (map.has(roomType)) {
            const existing = map.get(roomType);
            existing.cost += cost;
            existing.vendorDiscount += vendorDiscount;
            existing.availableRooms = Math.min(existing.availableRooms, availableRooms);
            existing.bookedRooms += bookedRooms;
          } else {
            map.set(roomType, {
              roomType,
              cost,
              availableRooms,
              bookedRooms,
              vendorDiscount,
              ...rest,
            });
          }
          return map;
        }, new Map())
      ).map(([_, value]) => value);
      this.setState({ inventory: groupedData });
  
      // Fetch images data
      const imagesResponse = await axios.get(
        `${baseUrl}/inventory/hostel-images/cloudinary?folder=${searchParams.get("hostel")}`
      );
      this.setState({ allImages: imagesResponse.data });
  
    } catch (error) {
      console.error("Error loading data:", error);
      // Optionally, you could show a message to the user indicating the error
      this.setState({ error: "Failed to load some data. Please try again later." });
    }
  }
  handleScrollToExperiences = () => {
    this.experiencesRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  handleScrollToAbout = () => {
    this.aboutRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  handleScrollToRooms = () => {
    this.roomRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  handleScrollToFacilities = () => {
    this.setState({ activePlaceOffersTab: "Facilities" });
    this.facilitiesRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  handleScrollReviews = () => {
    this.reviewsRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  generateUniqueColors = (count) => {
    const colors = new Set();
    while (colors.size < count) {
      colors.add(
        randomColor({
          luminosity: "dark"
        })
      );
    }
    return Array.from(colors);
  };
  render() {
    const { hostelId } = this.state;
    const onChange = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };
    const onReserve = () => {
      const { checkInDate, checkOutDate, totalAmount, bookingData, hostelId } =
        this.state;
      localStorage.setItem(
        "bookingData",
        JSON.stringify({
          totalAmount :parseFloat(totalAmount).toFixed(2),
          bookingData,
          checkInDate,
          checkOutDate,
          hostelId,
          comissionRateAbs:this.state.hostel[0]?.comissionRateAbs || 0,
        })
      );
      this.props.bookedHostel({
        totalAmount,
        bookingData,
      });
      window.location.href = `/booking`;
    };
    const settings  = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: false,
      arrows: true,
      indicator: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    };
    const roomAdded = (inventory) => {
      console.log(this.state.inventory)
      const { roomType, cost  ,vendorDiscount} = inventory;
      const { bookingData, days } = this.state;
      let roomData = bookingData;
      if (!roomData[roomType]) {
        roomData[roomType] = { totalRooms: 1, cost ,vendorDiscount :vendorDiscount  };
      } else {
        roomData[roomType].totalRooms += 1;
        roomData[roomType].vendorDiscount += vendorDiscount
      }
      console.log("room",roomData)
      const totalAmount = Object.values(roomData).reduce((sum, room) => {
        return sum +( room.totalRooms * room.cost - room.vendorDiscount) * 1.12;
      }, 0);
      this.setState({
        bookingData: roomData,
        totalAmount: totalAmount,
      });
    };

    const roomRemoved = (inventory) => {
      const { roomType , vendorDiscount} = inventory;
      const { bookingData, days  } = this.state;
      let roomData = bookingData;
      if (!roomData[roomType]) {
        return;
      } else {
        roomData[roomType].totalRooms -= 1;
        roomData[roomType].vendorDiscount -= vendorDiscount
        if (roomData[roomType].totalRooms === 0) delete roomData[roomType];
      }

      const totalAmount = Object.values(roomData).reduce((sum, room) => {
        return sum + (room.totalRooms * room.cost  - room.vendorDiscount) * 1.12 ;
      }, 0);
      this.setState({
        bookingData: roomData,
        totalAmount: totalAmount,
      });
    };


    const goToPrev = () => {
      this.sliderRef?.current.slickPrev();
    };
  
    const goToNext = () => {
      console.log("next",this.sliderRef?.current)
      this.sliderRef?.current.slickNext();
    };

    const amenities = this.state.hostel[0]?.amenities || [];
    const uniqueColors = this.generateUniqueColors(amenities.length);
    return (
      <div className=" whole-page">
        <div className="main-container pt-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div
              style={{
                background: "linear-gradient(90deg, #DC7C4C 0%, #844A2E 73.9%)",
                WebkitBackgroundClip: "text", // For Safari
                backgroundClip: "text",
                color: "transparent",
              }}
              className="h2 fw-bold mb-0"
            >
              {this.state.hostel[0]?.hostel_name}
            </div>
            <div
              className="text-white px-3 py-1"
              style={{
                backgroundColor: "#2D7912",
                borderRadius: "2rem",
                width: "max-content",
                boxShadow: "0px 4px 4px 0px #00000040",
              }}
            >
              <i className="bi bi-star-fill"></i> {this.state.hostel[0]?.rating}
            </div>
          </div>
          <div className="d-flex gap-3">
            {this.state.hostel[0]?.amenities.map((eachAmenity,index) => {
              return (
                <div
                  className="text-white px-3 py-1"
                  style={{
                    backgroundColor: uniqueColors[index],
                    borderRadius: "0.5rem",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  {eachAmenity}
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-3 image-slider">
        <div className="inner-image-slider">
          <Slider {...settings}>
            {this.state.allImages.map((eachImage, index) => {
              return (
                <div
                  key={index}
                  style={{ cursor: "pointer"}}
                  onClick={() => this.setState({ isModal: true })}
                >
                  <img
                    src={eachImage}
                    alt={`image-${index}`}
                    style={{ width: "480px", height: "300px",
                      margin: "0", 
                      padding: "0", 

                     }}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
        </div>

        <div className="mt-5 nav-below-cont">
          <div
            className="nav-div">
            <button className="nav-text" onClick={this.handleScrollToAbout}>About</button>
            <button className="nav-text" onClick={this.handleScrollToFacilities}>Facilities</button>
            <button className="nav-text" onClick={this.handleScrollToRooms}>Rooms</button>
            <button className="nav-text" onClick={this.handleScrollToExperiences}>Experiences</button>
            {/* <button className="nav-text" onClick={this.handleScrollReviews}>Reviews</button> */}
          </div>
          {/* d-flex gap-3 justify-content-between mb-5 */}
          <div className=" d-flex gap-3 justify-content-between mb-5 about-book">
            <div style={{ maxWidth: '850px'}}>
              <h2 className=" h4 text-black mb-3 fw-bold">About</h2>
              <p className="text-black">{this.state.hostel[0]?.description}</p>

              {/* While you’re here say Hi! To */}
              {this.state.hostel?.[0]?.OtherFacilities?.petName && (
                <div className="pets">
                  <h4
                    className="h4 text-black fw-bold"
                    style={{ margin: "0 40px 0 0" }}
                  >
                    While you’re here say Hi! To
                  </h4>
                  <div className="d-flex">
                    <div className="pet-div">
                      {this.state.hostel?.[0]?.OtherFacilities?.petName?.cat?.map(
                        (c) => (
                          <div key={c} className="pet-btn">
                            <img src={catImg} alt="cat" />
                            <span>{c}</span>
                          </div>
                        )
                      )}
                      {this.state.hostel?.[0]?.OtherFacilities?.petName?.dog?.map(
                        (d) => (
                          <div key={d} className="pet-btn">
                            <img src={dogImg} alt="dog" />
                            <span>{d}</span>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
        {/* When you should  go there & not */}
        <div className=" pros-cons-container">
          {this.state.hostel?.[0]?.OtherFacilities?.pros && (
            <div className="pros-cons pros">
              <h4>When you should go there</h4>
              {this.state.hostel?.[0]?.OtherFacilities?.pros?.map((pro) => (
                <p key={pro}>{pro}</p>
              ))}
            </div>
          )}
          {this.state.hostel?.[0]?.OtherFacilities?.cons && (
            <div className="pros-cons cons">
              <h4>When you should not go there</h4>
              {this.state.hostel?.[0]?.OtherFacilities?.cons?.map((con) => (
                <p key={con}>{con}</p>
              ))}
            </div>
          )}
        </div>
            </div>
            {/* Booking summary */}
            <div className="d-flex gap-3 mb-3 justify-content-between flex-lg-row flex-column">
              <div>
                <Card style={{ borderRadius: "1.3rem" }}>
                  <CardBody>
                    <div className="fw-bold h4 text-dark mb-0">
                      Booking summary
                    </div>
                    <div className="fw-bold mb-3" style={{ color: "#ED452F" }}>
                      {this.state.days}{" "}
                      {this.state.days === 1 ? "Night" : "Nights"} starting from{" "}
                      <span className="me-1"></span>
                      <Moment format="DD MMM YYYY">
                        {this.state.checkInDate}
                      </Moment>
                    </div>
                    <div className="row row-cols-lg-2 row-cols-1 g-3 mb-3">
                      <div className="col">
                        <Label className="fw-bold">Check In</Label>
                        <Input
                          style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
                          placeholder="Check In Date"
                          type="date"
                          disabled
                          name="checkInDate"
                          onChange={onChange}
                          value={this.state.checkInDate}
                        />
                      </div>
                      <div className="col">
                        <Label className="fw-bold">Check Out</Label>
                        <Input
                          style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
                          placeholder="Check Out Date"
                          type="date"
                          disabled
                          name="checkOutDate"
                          onChange={onChange}
                          value={this.state.checkOutDate}
                        />
                      </div>
                    </div>
                    {Object.keys(this.state.bookingData).length !== 0 && (
                      <div>
                        {Object.keys(this.state.bookingData).map(
                          (roomType, index) => {
                            return (
                              <div key={index}>
                                <div className="fw-bold text-dark-o mb-2">
                                  {roomType}
                                </div>
                                <div className="d-flex justify-content-between mb-1">
                                  <div>
                                    <i className="bi bi-currency-rupee"></i>
                                    {this.state.bookingData[roomType].cost}{" "}
                                    <i className="bi bi-x"></i>
                                    {
                                      this.state.bookingData[roomType]
                                        .totalRooms
                                    }{" "}
                                  </div>
                                  <div className="fw-bold">
                                    <i className="bi bi-currency-rupee"></i>
                                    {this.state.bookingData[roomType].cost *
                                      this.state.bookingData[roomType]
                                        .totalRooms}
                                  </div>
                                </div>


                                <div className="d-flex justify-content-between mb-1">
                                  <div>Discount.</div>
                                  <div className="fw-bold">
                                    <i className="bi bi-currency-rupee"></i>
                                    {(
                                      this.state.bookingData[roomType].vendorDiscount 
                                   
                                    )?.toFixed(2)}
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between mb-1">
                                  <div>Tax.</div>
                                  <div className="fw-bold">
                                    <i className="bi bi-currency-rupee"></i>
                                    {(
                                      (this.state.bookingData[roomType].cost *
                                      this.state.bookingData[roomType]
                                        .totalRooms - this.state.bookingData[roomType].vendorDiscount ) *
                                      0.12
                                    )?.toFixed(2)}
                                  </div>
                                </div>
                                {/* <div className="d-flex justify-content-between mb-1">
                                                                <div className="fw-bold">Total</div>
                                                                <div className="fw-bold">
                                                                    <i className="bi bi-currency-rupee"></i>
                                                                    {parseInt(this.state.bookingData[roomType].cost * this.state.bookingData[roomType].totalRooms * this.state.days * 1.1)}
                                                                </div>
                                                            </div> */}
                                <hr className="hr mb-3" />
                              </div>
                            );
                          }
                        )}
                        <div className="d-flex justify-content-between mb-3">
                          <div className="fw-bold">Total</div>
                          <div className="fw-bold">
                            <i className="bi bi-currency-rupee"></i>
                            {this.state.totalAmount.toFixed(2)}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mb-3">
                          <div className="fw-bold">Payable Now</div>
                          <div className="fw-bold">
                            <i className="bi bi-currency-rupee"></i>
                            {(
                              this.state.totalAmount *
                              (this.state.hostel[0]?.payable_percent / 100)
                            ).toFixed(2)}
                          </div>
                        </div>
                        <Button
                          style={{ borderRadius: "0.5rem" }}
                          onClick={onReserve}
                          className="w-100 mb-3 bg-dark-o"
                        >
                          Book Now
                        </Button>
                        <Alert size="sm" color="info">
                          You can apply coupons while checkout.
                        </Alert>
                      </div>
                    )}
                    {Object.keys(this.state.bookingData).length === 0 && (
                      <a
                        style={{
                          borderRadius: "0.5rem",
                          boxShadow: "0px 4px 4px 0px #00000040",
                        }}
                        href="#select-rooms"
                        className="btn w-100 mb-3 bg-dark-o text-white"
                      >
                        Select room
                      </a>
                    )}
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>

        {/* Experinces images */}
        <div className="experience-div">
<div className="experience-inner-div">
  <div className="exp-main-left-div"> 
<div className="dest-text-div">
  <h1 className="experience-left-div-heading">
Experiences
  </h1>
  <h2 style={{color:"black"}} className="dest-left-div-sub-heading">
Delighting our customers with ---------
  </h2>
  </div>
</div>
<div className="exp-images-div" ref={this.experiencesRef}>
                <div
              className="exp-image-inner-div" >
                    {/* first image */}
              {this.state.allImages[0] &&     <div style={{color:'white' ,position:"relative",zIndex:100}}>
                <img
                  src={this.state.allImages[0]}
                  alt={`image-${this.state.hostel[0]?.location}`}
                  className="exp-slider-left"
                />
                <h1 className="exp-image-heading-sides">{this.state.hostel[0]?.location} </h1>
              </div>
              }
                    {/* second image */}
                 {this.state.allImages[1] &&  <div style={{color:'white' ,position:"relative",zIndex:100}}>
                  <img
                    src={this.state.allImages[1]}
                    alt={`image-${this.state.hostel[0]?.location}`}
                    className="exp-slider-center-image"
                  />
                 <h1 className="dest-image-heading-centre">{this.state.hostel[0]?.location} </h1>
                    </div>
                 }
{/* third image  */}
                   {this.state.allImages[2] &&  <div style={{color:'white' ,position:"relative",zIndex:100}}>
                    <img
                      src={this.state.allImages[2]}
                      alt={`image-${this.state.hostel[0]?.location}`}
                      className="exp-slider-left"
                    />
                            <h1 className="exp-image-heading-sides">{this.state.hostel[0]?.location}  </h1>
                  </div>
                   }

             {  this.state.allImages[3] &&
              <div style={{color:'white' ,position:"relative",zIndex:100}}>
              <img
                src={this.state.allImages[3]}
                alt={`image-${this.state.hostel[0]?.location}`}
                className="exp-slider-center-image"
              />
             <h1 className="dest-image-heading-centre">{this.state.hostel[0]?.location} </h1>
                </div>
             }

                 </div>

            
</div>
</div>
</div>
        {/*  What this place offers */}
        <div className="main-container">
          <h2 className="offers-heading">
            What’s this <span style={{color:"#fd590c"}}>  place offers </span>
          </h2>
          <div className="offer-heading-container">
            {this.state.hostel?.[0]?.type?.length > 0 && (
              <h3
                className={`place-offers ${
                  this.state.activePlaceOffersTab === "Vibes" && "active"
                }`}
                onClick={() => this.setState({ activePlaceOffersTab: "Vibes" })}
              >
                Vibes
              </h3>
            )}
            {this.state.hostel?.[0]?.OtherFacilities?.BedFacilities?.length >
              0 && (
              <div ref={this.facilitiesRef}>
              <h3
                className={`place-offers ${
                  this.state.activePlaceOffersTab === "Facilities" && "active"
                }`}
                onClick={() =>
                  this.setState({ activePlaceOffersTab: "Facilities" })
                }
              >
                Facilities
              </h3>
              </div>
            )}
            {this.state.hostel?.[0]?.OtherFacilities?.Rules &&
  Object.keys(this.state.hostel?.[0]?.OtherFacilities?.Rules).length > 0 && (
              <h3
                className={`place-offers ${
                  this.state.activePlaceOffersTab === "Rules" && "active"
                }`}
                onClick={() => this.setState({ activePlaceOffersTab: "Rules" })}
              >
                Rules
              </h3>
            )}
          </div>
          {this.state.activePlaceOffersTab === "Vibes" && (
            <div className="offer-container ">
              <hr className="vertical-hr" />
              <>
                {this.state.hostel?.[0]?.type && (
                  <div className="offers">
                    <div className="offer-heading">
                      <img
                        src={typeImg}
                        alt="type"
                        className="offer-heading-img"
                      />
                      <h3 style={{color:"#fd590c"}} >Type</h3>
                    </div>

                    <div className="offerings">
                      {this.state.hostel?.[0]?.type.map((btn) => (
                        <button button className="offerings-button">
                          {btn}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
                <hr className="hr" />
              </>
              <>
                {this.state.hostel?.[0]?.Character && (
                  <div className="offers">
                    <div className="offer-heading">
                      <img
                        src={characterImg}
                        alt="character"
                        className="offer-heading-img"
                      />
                      <h3 style={{color:"#fd590c"}} >Character</h3>
                    </div>
                    <div className="offerings">
                      {this.state.hostel?.[0]?.Character.map((btn) => (
                        <button button className="offerings-button">
                          {btn}
                        </button>
                      ))}
                    </div>
                  </div>
                )}{" "}
                <hr className="hr" />
              </>
              <>
                {this.state.hostel?.[0]?.Social && (
                  <div className="offers">
                    <div className="offer-heading">
                      <img
                        src={socialImg}
                        alt="social"
                        className="offer-heading-img"
                      />
                      <h3 style={{color:"#fd590c"}}>Social</h3>
                    </div>
                    <div className="offerings">
                      {this.state.hostel?.[0]?.Social.map((btn) => (
                        <button button className="offerings-button">
                          {btn}
                        </button>
                      ))}
                    </div>
                  </div>
                )}{" "}
                <hr className="hr" />
              </>
              <>
                {this.state.hostel?.[0]?.Diversity && (
                  <div className="offers">
                    <div className="offer-heading">
                      <img
                        src={diversityImg}
                        alt="diversity"
                        className="offer-heading-img"
                      />
                      <h3 style={{color:"#fd590c"}} >Diversity</h3>
                    </div>

                    <div className="offerings">
                      {this.state.hostel?.[0]?.Diversity.map((btn) => (
                        <button button className="offerings-button">
                          {btn}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </>
            </div>
          )}
          {this.state.activePlaceOffersTab === "Facilities" && (
            <div className="offer-container">   
              <hr className="vertical-hr" />
              <>
                {this.state.hostel?.[0]?.OtherFacilities?.HostelFacilities && (
                  <div className="offers">
                    <div className="offer-heading">
                      <img
                        src={hostelfacilitiesImg}
                        alt="hostel_facilities"
                        className="offer-heading-img"

                      />
                      <h3 style={{color:"#fd590c"}}>Hostel Facilities</h3>
                    </div>

                    <div className="offerings">
                      {this.state.hostel?.[0]?.OtherFacilities?.HostelFacilities.map(
                        (btn) => (
                          <button button className="offerings-button">
                            {btn}
                          </button>
                        )
                      )}
                    </div>
                  </div>
                )}{" "}
                <hr className="hr" />
              </>
              <>
                {this.state.hostel?.[0]?.OtherFacilities?.BedFacilities && (
                  <div className="offers">
                    <div className="offer-heading">
                      <img
                        src={bedImg}
                        alt="bed"
                        className="offer-heading-img"
                      />
                      <h3 style={{color:"#fd590c"}}>Bed Facilities</h3>
                    </div>

                    <div className="offerings">
                      {this.state.hostel?.[0]?.OtherFacilities?.BedFacilities.map(
                        (btn) => (
                          <button button className="offerings-button">
                            {btn}
                          </button>
                        )
                      )}
                    </div>
                  </div>
                )}{" "}
                <hr className="hr" />
              </>
              <>
                {this.state.hostel?.[0]?.StayFacilities && (
                  <div className="offers">
                    <div className="offer-heading">
                      <img
                        src={dormImg}
                        alt="dorm"
                        className="offer-heading-img"
                        style={{color:"#fd590c"}}
                      />
                      <h3 style={{color:"#fd590c"}}>Dorm Facilities</h3>
                    </div>

                    <div className="offerings">
                      {this.state.hostel?.[0]?.StayFacilities.map((btn) => (
                        <button button className="offerings-button">
                          {btn}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
                {/* <hr className='hr' /> */}
              </>
            </div>
          )}
          {this.state.activePlaceOffersTab === "Rules" && (
            <div className="offer-container ">
            <hr className="vertical-hr" />
            <>
              {this.state.hostel?.[0]?.OtherFacilities?.Rules?.checkin_checkout && (
                <div className="offers">
                  <div className="offer-heading">
                    <img
                      src={checkoutImg}
                      alt="checkin"
                      className="offer-heading-img"
                    />
                          <h3   style={{fontSize:"25.5px" ,color:"#fd590c"}}>Check-In | Out Time</h3>
                  </div>

                  <div className="offerings">
                    {this.state.hostel?.[0]?.OtherFacilities?.Rules?.checkin_checkout?.map((btn) => (
 <h1
 style={{
   fontFamily: "Montserrat",
   fontWeight: 600,
   fontSize: "20px",
   lineHeight: "24.38px",
   width: "169px",
   height: "18px",
   color: "black",
   //top: "215px", // Optional
   left: "0px", // Optional
   whiteSpace: "nowrap",
 }}
>
 {btn}
</h1>
                    ))}
                  </div>
                </div>
              )}
              <hr className="hr" />
            </>
            <>
            {this.state.hostel?.[0]?.OtherFacilities?.Rules?.early_checkin && (
                <div className="offers">
                  <div className="offer-heading">
                    <img
                      src={checkinImg}
                      alt="checkout"
                      className="offer-heading-img"
                    />
                    <h3  style={{color:"#fd590c"}} >Early Check-In</h3>
                  </div>

                  <div className="offerings">
                    {this.state.hostel?.[0]?.OtherFacilities?.Rules?.early_checkin                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             ?.map((btn) => (
 <h1
 style={{
   fontFamily: "Montserrat",
   fontWeight: 600,
   fontSize: "20px",
   lineHeight: "24.38px",
   width: "169px",
   height: "18px",
   color: "black",
   top: "215px", // Optional
   left: "0px", // Optional
   whiteSpace: "nowrap",
 }}
>
 {btn}
</h1>
                    ))}
                  </div>
                </div>
              )}{" "}
              <hr className="hr" />
            </>
            <>
            {this.state.hostel?.[0]?.OtherFacilities?.Rules?.alcohol && (
                <div className="offers">
                  <div className="offer-heading">
                    <img
                      src={alcoholImg}
                      alt="alcohol"
                      className="offer-heading-img"
                    />
                    <h3  style={{color:"#fd590c"}} >Alcohol</h3>
                  </div>

                  <div className="offerings">
                    {this.state.hostel?.[0]?.OtherFacilities?.Rules?.alcohol?.map((btn) => (
 <h1
 style={{
   fontFamily: "Montserrat",
   fontWeight: 600,
   fontSize: "20px",
   lineHeight: "24.38px",
   width: "281px",
   height: "18px",
   color: "#black",
   top: "367px", // Optional
   left: "381px", // Optional
   whiteSpace: "nowrap",
 }}
>
 {btn}
</h1>
                    ))}
                  </div>
                </div>
              )}{" "}
              <hr className="hr" />
            </>
            <>
            {this.state.hostel?.[0]?.OtherFacilities?.Rules?.smoking && (
                <div className="offers">
                  <div className="offer-heading">
                    <img
                      src={smokingImg}
                      alt="drugs"
                      className="offer-heading-img"
                    />
                    <h3  style={{color:"#fd590c"}} >Smoking</h3>
                  </div>

                  <div className="offerings">
                    {this.state.hostel?.[0]?.OtherFacilities?.Rules?.smoking?.map((btn) => (
 <h1
 style={{
   fontFamily: "Montserrat",
   fontWeight: 600,
   fontSize: "20px",
   lineHeight: "24.38px",
   width: "281px",
   height: "18px",
   color: "black",
   top: "367px", // Optional
   left: "381px", // Optional
   whiteSpace: "nowrap",
 }}
>
 {btn}
</h1>
                    ))}
                  </div>
                </div>
              )}{" "}
              <hr className="hr" />
            </>
            <>
            {this.state.hostel?.[0]?.OtherFacilities?.Rules?.pets && (
                <div className="offers">
                  <div className="offer-heading">
                    <img
                      src={petsImg}
                      alt="drugs"
                      className="offer-heading-img"
                    />
                    <h3  style={{color:"#fd590c"}} >Pets</h3>
                  </div>

                  <div className="offerings">
                    {this.state.hostel?.[0]?.OtherFacilities?.Rules?.pets?.map((btn) => (
 <h1
 style={{
   fontFamily: "Montserrat",
   fontWeight: 600,
   fontSize: "20px",
   lineHeight: "24.38px",
   width: "281px",
   height: "18px",
   color: "black",
   top: "367px", // Optional
   left: "381px", // Optional
   whiteSpace: "nowrap",
 }}
>
 {btn}
</h1>
                    ))}
                  </div>
                </div>
              )}{" "}
              <hr className="hr" />
            </>
            <>
            {this.state.hostel?.[0]?.OtherFacilities?.Rules?.age_restriction && (
                <div className="offers">
                  <div className="offer-heading">
                    <img
                      src={ageImg}
                      alt="food"
                      className="offer-heading-img"
                    />
                    <h3  style={{color:"#fd590c"}} >Age Restriction</h3>
                  </div>

                  <div className="offerings">
                    {this.state.hostel?.[0]?.OtherFacilities?.Rules?.age_restriction?.map((btn) => (
 <h1
 style={{
   fontFamily: "Montserrat",
   fontWeight: 600,
   fontSize: "20px",
   lineHeight: "24.38px",
   width: "281px",
   height: "18px",
   color: "black",
   top: "367px", // Optional
   left: "381px", // Optional
   whiteSpace: "nowrap",
 }}
>
 {btn}
</h1>
                    ))}
                  </div>
                </div>
              )}
            </>
          </div>
          )}
        </div>
     
        {/* Select Rooms */}
        <div id="select-rooms" className="main-container mt-5" ref={this.roomRef}>
          <div className="h2 text-black fw-bold mb-3">Select Rooms</div>
          {this.state.inventory.map((eachInventory) => {
            return (
              <div className="mb-3">
                <Card style={{ maxWidth: "900px" }} className="room-card">
                  <CardBody
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "self-start",
                      padding: "0",
                    }}
                  >
                    <div
                      className="d-flex gap-3 flex-xl-row flex-column  "
                      style={{ width: "100%" }}
                    >
                      <img
                        src={
                          this.state.allImages.find((url) => {
                            console.log(url)
                            const keyPart = url
                              ?.split("c_fill/")[1]
                              .split("?")[0];
                            const components = keyPart.split("_");
                            return (
                              components[0] === eachInventory.roomId &&
                              parseInt(components[1]) === eachInventory.hostelId
                            );
                          }) || { HostelRoom }
                        }
                        alt="hostelCo"
                        className="w-100"
                        style={{
                          borderRadius: "0.5rem",
                          maxWidth: "130px",
                          objectFit: "cover",
                        }}
                      />
                      <div
                        className=""
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div style={{ display: "block" }}>
                          <div className="room-name-heading">
                            {eachInventory.roomType}
                          </div>
                          <div>
                            <div>
                              <div className="select-room-price">
                                <i
                                  className="bi bi-currency-rupee"
                                  style={{
                                    transform: "translateY(3px)",
                                  }}
                                ></i>
                                <span>{eachInventory.cost}</span>
                              </div>
                              <div
                                className="text-black"
                                style={{
                                  display: "flex",
                                  gap: "1rem",
                                  fontWeight: "500",
                                  alignItems: "center",
                                  marginBottom: "5px",
                                }}
                              >
                                <div>
                                  <i className="bi bi-person text-black "></i>{" "}
                                  x1
                                </div>
                                <div>
                                  <i class="bi bi-moon"></i> {this.state.days}
                                </div>
                              </div>
                            </div>
                            <div
                              className="text-white px-3 py-1"
                              style={{
                                backgroundColor: "#2D7912",
                                borderRadius: "2rem",
                                width: "max-content",
                                boxShadow: "0px 4px 4px 0px #00000040",
                              }}
                            >
                              Only{" "}
                              {eachInventory.availableRooms -
                                eachInventory.bookedRooms}{" "}
                              beds available
                            </div>
                          </div>
                        </div>
                        {/* Add button */}
                        <div
                          className=" "
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {!this.state.bookingData[eachInventory.roomType] && (
                            <Button
                              onClick={() => roomAdded(eachInventory)}
                              style={{
                                boxShadow: "0px 2px 4px 0px #00000040",
                              }}
                              className="bg-white text-secondary-o px-4 fw-bold add-button border-1  "
                            >
                              Add
                            </Button>
                          )}

                          {this.state.bookingData[eachInventory.roomType] && (
                            <InputGroup className="border-0">
                              <Button
                                style={{ borderRadius: "0.5rem" }}
                                onClick={() => roomRemoved(eachInventory)}
                                className="add-button bg-white text-dark-o border-0 py-2"
                                size="sm"
                              >
                                <i className="bi bi-dash"></i>
                              </Button>
                              <Input
                                className="text-center fw-bold  bg-transparent text-black border-0"
                                style={{ width: "40px" }}
                                placeholder="0"
                                value={
                                  this.state.bookingData[eachInventory.roomType]
                                    .totalRooms
                                }
                              />
                              <Button
                                style={{ borderRadius: "0.5rem" }}
                                onClick={() => roomAdded(eachInventory)}
                                className="add-button bg-white fw-bold text-dark-o border-0 py-2"
                                size="sm"
                              >
                                <i className="bi bi-plus-lg"></i>
                              </Button>
                            </InputGroup>
                          )}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            );
          })}
        </div>

        <div className="reviews" ref={this.reviewsRef} >
<h1 className="review-heading">
  Reviews
</h1>
<div className="slider-reviews">
<Slider {...smallDeviceSlider} ref={this.sliderRef}>  
        { this.state.reviews.map((eachReview) =>(
  <div className="user-review" style={{width:"20rem"}}>
    <img className="review-avatar" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" />
  <div className="user-review-text-div">
<p className="review-para">{eachReview.review}</p>
<p className="user-review-name">
  {eachReview.name}
</p>
</div>
</div>
  ))
}
  </Slider>

  <div className="custom-arrow next " onClick={goToNext}>
       <i   style={{fontSize:"25px",color:'white'}} class="bi bi-caret-right-fill"></i>
    </div>

    <div className="custom-arrow prev" onClick={goToPrev}>
       <i  style={{fontSize:"25px",color:'white'}} class="bi bi-caret-left-fill"></i>
  </div>

</div>

        </div>
        <div className="map-div">
          <div className="Location-heading">Location</div>
          <div className="map-container">
          <iframe
            src={this.state.hostel[0]?.location_url}
            title="maps"
            width="100%"
            height="450"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          </div>

        </div>
        <Footer />
        <Modal
          scrollable
          size="xl"
          isOpen={this.state.isModal}
          toggle={() => this.setState({ isModal: false })}
        >
          <ModalHeader toggle={() => this.setState({ isModal: false })}>
            All Hostel Images
          </ModalHeader>
          <ModalBody>
            <div className="row row-cols-1 row-cols-lg-3">
              {this.state.allImages.map((eachImage) => {
                return (
                  <div key={eachImage} className="row">
                    <img className="m-2" src={eachImage} alt="hostelco" />
                  </div>
                );
              })}
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bookingData: state.booking.bookingData,
});

const mapDispatchToProps = {
  bookedHostel,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HostelPage)
);
