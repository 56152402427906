import React from "react";
import {
    Card,
    CardBody,
    Input,
    Accordion,
    AccordionItem,
    AccordionBody,
    AccordionHeader,
} from "reactstrap";
import Footer from "../Components/Footer"
import withRouter from "../Components/withRouter"
import axios from "axios";
import { baseUrl } from "../index"
import SelectSearch from "react-select-search";
import "../App.css"
import MapComponent from "../Components/SearchMap";
import { amenityCalculator, overallFilter, rateCalculator, roomTypeCalculator } from "../Utils/searchFilters";
import { pink, red } from "@mui/material/colors";
import { Helmet } from "react-helmet";
import faq from "../Assets/faq.png";
import randomColor from "randomcolor";
import MyMap from "../Components/SearchMap";

class SearchPage extends React.Component {
    constructor() {
        super()
        this.state = {
            fromDate: "",
            toDate: "",
            searchInput: "",
            otherSearch: "",
            amenity: "",
            allHostels: [],
            filteredHostels: [],
            roomTypeFilter: [],
            amenityFilter: [],
            ratingFilter: [],
            searchText: "", // New: To store search_text
            faq: "",
            open: false,
            faqQuestions: [],
            faqAnswers: [],
            priceFilterValue: "",
            amenityFilterValue: "",
            ratingFilterValue: "",
            roomTypeFilterValue: "",
            loading: true,
        }
    }

    componentDidMount() {
        const { searchParams } = this.props.router
        const city = searchParams.get("city");
        const amenity = searchParams.get("amenity");
        const fromDate = searchParams.get("from");
        const toDate = searchParams.get("to");
        const temp = searchParams.get("otherSearch")
        const today = new Date()
        today.setHours(0, 0, 0, 0);
        const newCheckIn = new Date(fromDate);
        newCheckIn.setHours(0, 0, 0, 0);
        if (newCheckIn < today) {
            alert('Error: Check-in date cannot be earlier than today.');
            window.location.href = "/";
            return;
        }
        this.setState({ searchInput: city || "", amenity: amenity || "", fromDate, toDate, otherSearch: temp }, () => {
            const { searchInput, amenity, fromDate, toDate, otherSearch } = this.state

            let apiUrl = `${baseUrl}/inventory/search-results?`;
            apiUrl += `location=${searchInput}&amenity=${amenity}&checkInDate=${fromDate}&checkOutDate=${toDate}&otherSearch=${otherSearch}`;
            this.setState({ loading: true })
            axios.get(apiUrl).then(response => {
                // Destructure seo_data and hostels
                const hostels = response.data.hostels || [];
                console.log(hostels)
                const seodata = response.data.seo_data || {};
                const mapData = response.data.mapData || [];
                const mapCenter = response.data.mapCenter || [];
                const mapZoom = response.data.mapZoom || 10;
                console.log(mapZoom)
                console.log(mapData)
                // Parse FAQ data
                const faqData = seodata.faq || '';
                // Split FAQ string into question-answer pairs by 'Q:'
                const faqPairs = faqData.split('Q:').filter(pair => pair.trim() !== '');  // Remove empty string due to initial Q:
                const questions = [];
                const answers = [];
                faqPairs.forEach(pair => {
                    // Split each pair by 'A:' to separate question and answer

                    const [question, answer] = pair.split('A:');

                    if (question && answer) {
                        const cleanedQuestion = question.replace(/;/g, '').trim();
                        const cleanedAnswer = answer.replace(/;/g, '').trim();
                        questions.push(cleanedQuestion);  // Clean up leading/trailing spaces
                        answers.push(cleanedAnswer);      // Clean up leading/trailing spaces
                    }
                });

                if (!hostels.length) {
                    this.setState({
                        faqQuestions: questions,
                        faqAnswers: answers,
                        searchText: seodata.search_text || "",
                        loading: false 
                    });
                    return;
                }
                
                const roomTypeFilter = roomTypeCalculator(hostels)
                const amenityFilter = amenityCalculator(hostels)
                const ratingFilter = rateCalculator(hostels)
                
                this.setState({ allHostels: hostels, filteredHostels: hostels, mapData: mapData, mapCenter:mapCenter, mapZoom:mapZoom, roomTypeFilter, amenityFilter, ratingFilter, searchText: seodata.search_text || "", // Set search_text
                    faqQuestions: questions, faqAnswers: answers, loading: false
                })
            }).catch(err => {
                this.setState({ loading: false })
                console.log(err.message)
            })
        })
    }

    generateUniqueColors = (count) => {
        const colors = new Set();
        while (colors.size < count) {
          colors.add(
            randomColor({
              luminosity: "dark"
            })
          );
        }
        return Array.from(colors);
      };

    render() {
        const { loading, faqQuestions, faqAnswers } = this.state

        const onHostel = (id) => {
            const { fromDate, toDate } = this.state
            window.location.href = `/hostel?hostel=${id}&from=${fromDate}&to=${toDate}`
        }

        const onChange = (e) => {
            const { name, value } = e.target;
            const { allHostels, priceFilterValue, ratingFilterValue, roomTypeFilterValue, amenityFilterValue, searchText, faq } = this.state;

            this.setState({ [name]: value }, () => {
                const Filters = {
                    priceFilterValue: name === 'priceFilterValue' ? value : priceFilterValue,
                    ratingFilterValue: name === 'ratingFilterValue' ? value : ratingFilterValue,
                    roomTypeFilterValue: name === 'roomTypeFilterValue' ? value : roomTypeFilterValue,
                    amenityFilterValue: name === 'amenityFilterValue' ? value : amenityFilterValue,
                };

                const filteredHostels = overallFilter(allHostels, Filters);
                this.setState({ filteredHostels });
            });
        };

        const toggle = (id) => {
            if (this.state.open === id) {
                this.setState({ open: "" });
            } else {
                this.setState({ open: id });
            }
        };


        if (loading) {
            return (
                <div className="loading-frame">
                    <div className="spinner"></div>
                    <span className="loading-text">Loading...</span>
                </div>
            );
        }
        const amenities = this.state.filteredHostels[0]?.amenities || [];
        const uniqueColors = this.generateUniqueColors(amenities.length);

        return (
            <div className="bg-white">
                <Helmet>
                    <title>Best Hostels in {this.state.searchInput?.charAt(0).toUpperCase() + this.state.searchInput?.slice(1).toLowerCase()} | TheHostelCo for Solo & Party Trips</title>
                    <meta 
                        name = "description" 
                        content = {`Discover TheHostelCo's top hostels in ${this.state.searchInput?.charAt(0).toUpperCase() + this.state.searchInput?.slice(1).toLowerCase()}, perfect for solo travelers and party lovers. Explore Anjuna and North Goa's best backpacker spots. Book now!`}
                    />
                    <h1>Explore Top-Rated Hostels in {this.state.searchInput?.charAt(0).toUpperCase() + this.state.searchInput?.slice(1).toLowerCase()} with TheHostelCo</h1>
                </Helmet>
                <div className="pt-5 border">
                    <div className="main-container" style={{textAlign: "left" }}>
                        <div className="text-black fw-bold h2 mb-3">
{this.state.filteredHostels.length} 
{this.state.filteredHostels.length <= 1 ? " hostel" : " hostels"} found in {this.state.searchInput
  ? this.state.searchInput.charAt(0).toUpperCase() + this.state.searchInput.slice(1).toLowerCase()
  : this.state.otherSearch}
</div>
                        {this.state.allHostels.length === 0 &&
                            <div>
                                No hostel is available
                            </div>}
                    </div>
                    <div className="bg-green flex items-center justify-center py-4">
                        <div className=" main-container d-flex gap-3 flex-wrap" style={{ justifyContent: "flex-start" }}>
                            <Input onChange={onChange} value={this.state.priceFilterValue} name="priceFilterValue" style={{ borderRadius: "2rem", width: "max-content" }} type="select">
                                <option value="">Price</option>
                                <option value="LH">Price - low to high</option>
                                <option value="HL">Price - high to low</option>
                            </Input>
                            <Input onChange={onChange} value={this.state.amenityFilterValue} name="amenityFilterValue" style={{ borderRadius: "2rem", width: "max-content" }} type="select">
                                <option value="">Amenities</option>
                                {this.state.amenityFilter.map(eachAmenity => {
                                    return (
                                        <option value={eachAmenity} key={eachAmenity}>{eachAmenity}</option>
                                    )
                                })}
                            </Input>
                            <Input onChange={onChange} value={this.state.ratingFilterValue} name="ratingFilterValue" style={{ borderRadius: "2rem", width: "max-content" }} type="select">
                                <option value="">Rating</option>
                                {this.state.ratingFilter.map(eachRating => {
                                    return (
                                        <option value={eachRating} key={eachRating}>{eachRating}</option>
                                    )
                                })}
                            </Input>
                            <Input onChange={onChange} value={this.state.roomTypeFilterValue} name="roomTypeFilterValue" style={{ borderRadius: "2rem", width: "max-content" }} type="select">
                                <option value="">Room Type</option>
                                {this.state.roomTypeFilter.map(eachRoomType => {
                                    return (
                                        <option value={eachRoomType} key={eachRoomType}>{eachRoomType}</option>
                                    )
                                })}
                            </Input>
                        </div>
                    </div>

                    <div className=" main-container d-flex row row-cols-1 row-cols-lg-2 g-3 mb-3" style={{width:"92%"}}>
                        <div className="col">
                            {this.state.filteredHostels.map(eachCard => {
                                return (
                                    <div key={eachCard} className="col">
                                        <Card onClick={() => onHostel(eachCard.id)} style={{ cursor: "pointer",border: "2px solid black", borderRadius: "1rem", overflow: "hidden", alignItems: "flex-start"}} className="feature-card mb-3">
                                            <CardBody>
                                                <div className="d-flex gap-4 flex-wrap">
                                                    <div>
                                                        <img src={eachCard.thumbnail} style={{ borderRadius: "1rem", marginBottom: "4rem", width: "300px", height: "200px", objectFit: "cover"}} alt="HostelCo" />
                                                        <div style={{ borderRadius: "1rem", marginTop: "-6.5rem", width: "max-content", zIndex: "4", backgroundColor: "#251313", opacity: '0.9' }} className="bg-tertiary-o px-2 py-1 ms-2 text-white">Rs. {eachCard.price}</div>
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-between">
                                                        <div>
                                                            <div className="text-red fw-bold">{eachCard.hostel_name}</div>
                                                            <div className="text-text mb-2">{eachCard.location}</div>
                                                            <div className="d-flex gap-2 flex-wrap mb-5">
                                                                {eachCard.amenities.map((eachAmenity,index) => {
                                                                    return (
                                                                        <div className="px-2 py-1 rounded text-white" style={{ backgroundColor: uniqueColors[index], fontSize: "0.75rem", boxShadow: "0px 4px 4px 0px #00000040", }}>{eachAmenity}</div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="text-white px-3 py-1" style={{ backgroundColor: "#2D7912", borderRadius: "2rem", width: "max-content", boxShadow: "0px 4px 4px 0px #00000040", }}>
                                                            <i className="bi bi-star-fill"></i> {eachCard.rating}
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                )
                            })}

                        </div>
                        {this.state.filteredHostels.length > 0 && (
                        <div className="col d-flex flex-grow-1">
                            <MyMap mapData={this.state.mapData} mapCenter={this.state.mapCenter} mapZoom={this.state.mapZoom} /> {/* Add the MyMap component here */}
                        </div>
                        )}
                    </div>
                </div>
                <div className="bg-dark-o">
                    <div className="main-container d-flex gap-3 justify-content-between m-5 flex-wrap">
                        <div>
                            <div className=" h4 text-white my-3 fw-bold">About</div>
                            <div className="text-white mb-5">
                                {this.state.searchText}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    <div className="main-container py-5">
                        <div className="heading-font-2 text-red h2 text-center mb-5">
                            Frequently asked questions
                        </div>
                        <div className="faq-div">
                        <Accordion open={this.state.open} toggle={toggle}>
                            {faqQuestions && faqQuestions.length > 0 ? (
                                faqQuestions.map((question, index) => (
                                    <AccordionItem key={index} className="mb-3 bg-transparent border-none">
                                        <AccordionHeader
                                            targetId={index + 1}
                                            className="accordion-button-none"
                                        >
                                            <p className="me-3 text-dark">{question}</p>
                                            <i
                                                className="bi bi-plus-circle-fill position-absolute end-0 ms-5 me-3 fs-1"
                                                style={{ opacity: 0.5 }}
                                            ></i>
                                        </AccordionHeader>
                                        <AccordionBody
                                            className="text-dark"
                                            accordionId={index + 1}
                                        >
                                            {faqAnswers[index]}
                                        </AccordionBody>
                                    </AccordionItem>
                                ))
                            ) : (
                                <div>No FAQs available</div>
                            )}
                        </Accordion>
                        <div className='faq-img-div'>
                            <img src={faq} alt='faq' className='faq-img' />
                        </div>
                    </div>
                </div>
            </div>

                <Footer />
            </div>
        )
    }
}

export default withRouter(SearchPage)
