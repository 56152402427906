import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import { baseUrl } from "../index";
const ExperiencesCarousel = ({hostelId}) => {
    const [experiences, setExperiences] = useState([]);
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3, // Number of columns for desktop
            slidesToSlide: 1, // Number of slides to scroll
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2, // Number of columns for tablet
            slidesToSlide: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1, // Number of columns for mobile
            slidesToSlide: 1,
        },
    };


    useEffect(() => {
        const fetchExperiences = async () => {
            try {
                const response = await axios.get(
                    `${baseUrl}/hostel/experiences/cloudinary?folder=${hostelId}`
                );
                console.log(response)
                setExperiences(response.data);
            } catch (error) {
                console.error("Error fetching experiences:", error);
            }
        };

        fetchExperiences();
        console.log(experiences)
    }, [hostelId]);
    const CustomLeftArrow = ({ onClick }) => (
        <button
            onClick={onClick}
            style={{
                position: "absolute",
                left: "15px",
                top: "50%",
                transform: "translateY(-50%)",
                background: "none",
                border: "none",
                padding: "0",
                cursor: "pointer",
                zIndex: 1000,
            }}
        >
            <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <polyline points="15 18 9 12 15 6" />
            </svg>
        </button>
    );

    const CustomRightArrow = ({ onClick }) => (
        <button
            onClick={onClick}
            style={{
                position: "absolute",
                right: "15px",
                top: "50%",
                transform: "translateY(-50%)",
                background: "none",
                border: "none",
                padding: "0",
                cursor: "pointer",
                zIndex: 1000,
            }}
        >
            <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <polyline points="9 18 15 12 9 6" />
            </svg>
        </button>
    );
    const CustomDot = ({ onClick, active }) => (
        <div
            onClick={onClick}
            style={{
                width: active ? "31px" : "27px",
                height: active ? "31px" : "27px",
                top: "2690px",
                left: "683px",
                backgroundColor: active ? "#7C7C7C" : "#C8C8C8",
                margin: "0 5px",
                borderRadius: "50%",
                cursor: "pointer",
                display: "inline-block",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)"
            }}
        ></div>
    );
    return (
        <div id='select-rooms' className='main-container mt-5'>
          <div className='h2 text-black fw-bold mb-3'>Experiences</div>
            <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                showDots={true}
                containerClass="carousel-container"
                itemClass="carousel-item-padding-10-px"
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
                customDot={<CustomDot />}
            >
                {experiences.map((experience, index) => {
                    return (
                        <div key={index} style={{ textAlign: "center", padding: "10px" }}>
                            <img
                                src={experience.url}
                                alt={experience.title}
                                style={{
                                    width: "300px",
                                    height: "412px",
                                    top: "2192px", left: "490px", borderRadius: "10px",
                                    margin: "0 5px",
                                    border: "4px solid #B0B0B0",
                                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.4)",
                                }}
                            />
                            <p    style={{
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "48px",
        width: "232px",
        height: "48px",
        margin: "0 auto 20px auto",
        textAlign: "center", 
        whiteSpace: "nowrap", 
        overflow: "hidden", 
        textOverflow: "ellipsis",
    }}>{experience.title}</p>
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default ExperiencesCarousel;
