// import React, { useState, useEffect } from "react";
// // import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
// import { useSearchParams } from "react-router-dom";
// import axios from "axios";
// // import "leaflet/dist/leaflet.css";
// import { baseUrl } from "..";

// // Fix for Leaflet marker icons not displaying correctly
// // import L from "leaflet";
// // import markerIcon from "leaflet/dist/images/marker-icon.png";
// // import markerShadow from "leaflet/dist/images/marker-shadow.png";

// // const defaultIcon = L.icon({
// //   iconUrl: markerIcon,
// //   shadowUrl: markerShadow,
// //   iconSize: [25, 41],
// //   iconAnchor: [12, 41],
// // });

// // L.Marker.prototype.options.icon = defaultIcon;

// const MapComponent = () => {
//   const [searchParams] = useSearchParams();
//   const rawLocationId = searchParams.get("city");

//   // Capitalize the first letter of locationId
//   const locationId = rawLocationId
//     ? rawLocationId.charAt(0).toUpperCase() + rawLocationId.slice(1)
//     : null;

//   const [hostels, setHostels] = useState([]);
//   const [amenities, setAmenities] = useState([]);
//   const [center, setCenter] = useState([20, 78]); // Default center (India)

//   useEffect(() => {
//     axios
//       .get(${baseUrl}/search/maps/${locationId})
//       .then((response) => {
//         const { hostels, amenities } = response.data;

//         setHostels(hostels);
//         setAmenities(amenities);

//         // Set map center to the first hostel
//         if (hostels.length > 0) {
//           setCenter([hostels[0].lat, hostels[0].lon]);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching map data:", error);
//       });
//   }, []);

//   return (
//     // <MapContainer center={center} zoom={13} style={{ height: "600px", width: "100%" }}>
//       {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

//       {hostels.map((hostel) => (
//         <Marker key={hostel.id} position={[hostel.lat, hostel.lon]}>
//           <Popup>
//             <strong>{hostel.name}</strong>
//             <br />
//             {hostel.description}
//           </Popup>
//         </Marker>
//       ))}
//       {amenities.map((amenity) => (
//         <Marker
//           key={amenity.id}
//           position={[amenity.lat, amenity.lon]}
//           icon={L.icon({
//             iconUrl: markerIcon,
//             iconSize: [20, 30],
//             iconAnchor: [10, 30],
//             popupAnchor: [0, -30],
//           })}
//         >
//           <Popup>
//             <strong>{amenity.name}</strong>
//             <br />
//             {amenity.description}
//           </Popup>
//         </Marker>
//       ))} */}
//     // </MapContainer>
//   );
// };

// export default MapComponent;

import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup,Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const MyMap = ({mapData,mapCenter,mapZoom}) => {
  // const position = [37.7749, -122.4194]; // Example coordinates (San Francisco)
  console.log(mapData);
  const [center, setCenter] = useState(mapCenter);
  const [zoom, setZoom] = useState(mapZoom); 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (mapCenter && Array.isArray(mapCenter) && mapCenter.length === 2) {
      setCenter(mapCenter);
    }
    if (mapZoom && !isNaN(mapZoom)) {
      setZoom(mapZoom);
    }
    setLoading(false);
  }, [mapData,mapCenter, mapZoom]);
  
  const createIcon = (iconUrl) => {
    return L.icon({
      iconUrl: iconUrl,
      iconSize: [32, 32], // Size of the icon [width, height]
      iconAnchor: [16, 32], // Point of the icon which will correspond to the marker's location
      popupAnchor: [0, -32], // Point from which the popup should open relative to the iconAnchor
    });
  };

  if (!mapData || mapData.length === 0) {
    return <div className="h-64 flex items-center justify-center">No location data available</div>;
  }
  
  if (loading || !center.length) {
    return <div>Loading map...</div>; // Show loading message while data is being fetched
  }
  
  return (
    <MapContainer center={center} zoom={zoom} style={{ height: '100%', width: '100%',minHeight: '400px' }} key={`${center[0]}-${center[1]}`}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
        maxZoom={20}
      />
      {mapData.map((location) => (
        location.latitude && location.longitude ? (
          <Marker 
            key={location.id} 
            position={[location.longitude, location.latitude]}
            icon={createIcon(location.icon_url)}
          >
            <Tooltip direction="top" offset={[0, -20]} opacity={1} permanent>
              <span>{location.propertyName}</span>
            </Tooltip>
            <Popup>
              <div className="p-2">
                <h3 className="font-semibold text-lg mb-1">{location.propertyName}</h3>
                <div className="text-sm text-gray-600">
                  <p>Type: {location.propertyType}</p>
                  <p>Location: {location.baseLocation}</p>
                  <p>{location.city}, {location.state}</p>
                </div>
              </div>
            </Popup>
          </Marker>
        ) : null
      ))}
    </MapContainer>
  );
};

export default MyMap;
