import React from "react";
import Logo from "../Assets/Logo.svg";
import axios from "axios";
import { baseUrl } from "../index";
import SelectSearch from "react-select-search";
import { Button, Input } from "reactstrap";
import withRouter from "./withRouter";
const currentLocation = window.location.pathname;

const navLinks = [
  { name: "Home", path: "#home" },
  { name: "Explore", path: "#explore" },
  { name: "Rooms", path: "#rooms" },
  { name: "About", path: "#about" },
  { name: "Contact", path: "#contact" },
];

class Toolbar extends React.Component {
  constructor() {
    super();
    this.state = {
      searchInput: "",
      fromDate: "",
      toDate: "",
      allHostels: [],
      allLocations: [],
      city: "",
      open: "0",
      nextDate: "",
      hostel: {},
      otherSearch: "",
    };
  }
  componentDidMount() {
    axios
      .get(`${baseUrl}/hostel/all-hostels`)
      .then((response) => {
        this.setState({ allHostels: response.data });
      })
      .catch((err) => {
        console.log(err.message);
      });
    axios
      .get(`${baseUrl}/hostel/hostel-location`)
      .then((response) => {
        this.setState({ allLocations: response.data });
      })
      .catch((err) => {
        console.log(err.message);
      });
    const { searchParams } = this.props.router;
    const city = searchParams.get("city") || "Location";
    const fromDate = searchParams.get("from") || null;
    const toDate = searchParams.get("to") || null;
    const nextDay = new Date(fromDate);
    const otherSearchParams = searchParams.get("otherSearch");
    nextDay.setDate(nextDay.getDate() + 1);
    this.setState({ nextDate: nextDay.toISOString().split("T")[0] });
    if (currentLocation === "/search") {
      this.setState({ searchInput: city, otherSearch: otherSearchParams });
    }
    if (currentLocation === "/hostel") {
      axios
        .get(
          `${baseUrl}/hostel/hostel/${searchParams.get(
            "hostel"
          )}?otherSearch=${searchParams.get("otherSearch")}`
        )
        .then((response) => {
          this.setState({
            hostel: response.data[0],
            searchInput: response.data[0].hostel_name,
          });
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    this.setState({ fromDate: fromDate, toDate: toDate, city: city });
  }
  render() {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const onChange = (e) => {
      const { name, value } = e.target;
      if (name === "fromDate") {
        const nextDay = new Date(value);
        nextDay.setDate(nextDay.getDate() + 1);
        this.setState({ nextDate: nextDay.toISOString().split("T")[0] });
      }
      this.setState({ [name]: value });
    };
    const onSubmit = () => {
      const { searchInput, fromDate, toDate, otherSearch } = this.state;
      console.log(currentLocation);
      if (currentLocation === "/search") {
        window.location.href = `/search?city=${searchInput}&from=${fromDate}&to=${toDate}&otherSearch=${otherSearch}`;
      } else {
        window.location.href = `/hostel?hostel=${params.get(
          "hostel"
        )}&from=${fromDate}&to=${toDate}&otherSearch=${otherSearch}`;
      }
    };
    const showSearchBar =
      currentLocation === "/hostel" || currentLocation === "/search";
    const { fromDate, toDate } = this.state;
    return (
      <div className="py-3 bg-white">
        <div className=" nav-bar">
          <div className="">
            <a href="/">
              <img
                src={Logo}
                alt="logo"
                className="d-none d-md-inline-block"
                style={{ height: "40px" }}
              />
              <img
                src={Logo}
                alt="logo"
                className="d-md-none d-inline-block"
                style={{ height: "25px" }}
              />
            </a>
          </div>
          {showSearchBar && (
            <div
              className="d-inline-flex col-2 row row-cols-10 py-1 mx-auto align-items-center justify-content-between"
              style={{
                backgroundColor: "#FDF4F4",
                color: "#200707",
                borderRadius: "2rem",
                width: "max-content",
              }}
            >
              <div className="col-3">
                <SelectSearch
                  options={this.state.allLocations}
                  name="searchInput"
                  search
                  onChange={(value) => {
                    this.setState({
                      searchInput: value,
                    });
                  }}
                  placeholder={this.state.searchInput}
                  disabled={currentLocation === "/hostel"}
                />
              </div>
              <div
                className="col-6 custom-dates-index"
                style={{ borderLeft: "2px solid " }}
              >
                <div className="row row-cols-2 align-items-center justify-content-between">
                  <div className="col position-relative align-content-cente align-items-center">
                    <Input
                      min={new Date().toISOString().split("T")[0]}
                      className="border-0 custom-date-input m-0 p-0 bg-transparen opacity-0 position-absolute"
                      onChange={onChange}
                      value={this.state.fromDate}
                      placeholder="From"
                      name="fromDate"
                      type="date"
                    />
                    <div className="text-wrap">{fromDate}</div>
                  </div>
                  <div className="col position-relative align-content-center">
                    <Input
                      min={this.state.nextDate}
                      className="border-0 custom-date-input m-0 p-0 col bg-transparent opacity-0 position-absolute"
                      onChange={onChange}
                      value={this.state.toDate}
                      placeholder="To"
                      name="toDate"
                      type="date"
                    />
                    {toDate}
                  </div>
                </div>
              </div>
              <div className="col-auto p-0">
                <Button
                  onClick={onSubmit}
                  size="sm"
                  style={{ padding: "0.75rem 1rem" }}
                  className="book-button"
                >
                  <i className="bi bi-search"></i>
                </Button>
              </div>
            </div>
          )}

          {!showSearchBar && (
            <div className="nav-links">
              {navLinks.map((links) => (
                <div className="single-link">
                  <a href={links.path}>{links.name}</a>
                </div>
              ))}
                <img
                className="review-avatar"
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
              />
            </div>
          )}
     
        </div>
      </div>
    );
  }
}

export default withRouter(Toolbar);
