import React from "react";
import Footer from "../Components/Footer";
import {
  Card,
  CardBody,
  CardHeader,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Button,
  FormGroup,
  Alert,
} from "reactstrap";
import axios from "axios";
import { baseUrl } from "..";
import withRouter from "../Components/withRouter";
import firebase from "firebase";
import { auth } from "../config";
import { connect } from "react-redux";
import { bookedHostel } from "../Redux/booking";

class BookingPage extends React.Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      middleName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      isOtpSent: false,
      otp: "",
      terms: false,
      couponCode: "",
      couponValue: 0,
      message: "",
      messageColor: "",
      days: 0,
      price: 0,
      hostel: {},
      isCouponApplied: false,
      isOTPValid: false,
      result: {},
      bookingData: {},
      is18: false,
      totalDistributed: "",
      couponType: "",
      totalAmountOfRooms: "",
    };
  }

  componentDidMount() {
    const bookingData = JSON.parse(localStorage.getItem("bookingData"));
    axios
      .get(`${baseUrl}/hostel/hostel/${bookingData.hostelId}`)
      .then((response) => {
        this.setState(
          {
            hostel: response.data,
            bookingData: JSON.parse(localStorage.getItem("bookingData")),
            totalAmountOfRooms: bookingData.totalAmount,
          },
          () => {
            const d1 = new Date(this.state.bookingData.checkInDate);
            const d2 = new Date(this.state.bookingData.checkOutDate);
            const timeDifference = Math.abs(d2 - d1);
            const differenceInDays = Math.ceil(
              timeDifference / (1000 * 60 * 60 * 24)
            );
            this.setState({
              days: differenceInDays,
              hostelId: this.state.bookingData.hostelId,
            });
          }
        );
      });

    const fetchCoupon = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/coupon/default-coupons/${
            JSON.parse(localStorage.getItem("bookingData")).hostelId
          }`
        );
        console.log(response.data);
        if (!response.data.error) {
          //updating total price if coupon is available
          this.setState(
            {
              couponCode: response.data.couponCode,
              couponValue: response.data.couponValue,
              isCouponApplied: true,
              couponType: response.data.couponType,
            },
            () => {
              updatePrice(response.data);
            }
          );
        } else {
          //updating total price if coupon is not available
          this.setState({
            price: bookingData.totalAmount,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchCoupon();

    const updatePrice = (couponData) => {
      let newPrice = bookingData.totalAmount;
      let couponValue = couponData.couponValue;
      if (couponData.couponType === "value") {
        newPrice = bookingData.totalAmount - couponData.couponValue;
        couponValue = couponData.couponValue;
      } else if (couponData.couponType === "percent") {
        newPrice =
          bookingData.totalAmount -
          couponData.couponValue * 0.01 * bookingData.totalAmount;

        couponValue = bookingData.totalAmount * (couponValue / 100);
      }

      this.setState({
        price: newPrice,
        couponValue: couponValue,
      });
    };
  }

  handleCheckbox = () => {
    this.setState((prevState) => ({ is18: !prevState.is18 }));
  };

  render() {
    const onChange = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };
    const onSubmit = async () => {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) return;
      const {
        firstName,
        middleName,
        lastName,
        phoneNumber,
        email,
        bookingData,
        isOTPValid,
        hostel,
        price,
        couponCode,
        couponValue,
        isCouponApplied,
        days,
      } = this.state;
      console.log(this.state.isCouponApplied);

      const allBookedRooms = bookingData.bookingData; // Booking data
      const roomKeys = Object?.keys(allBookedRooms); // Get keys of all booked rooms
      if (isCouponApplied) {
        //subtracting couponAmt from totalAmount
        bookingData.totalAmount -= couponValue;
      }
      if (!bookingData.totalDiscount) {
        bookingData.totalDiscount = 0;
      }
      roomKeys.forEach((roomKey, index) => {
        const room = allBookedRooms[roomKey];
        room.avgPrice = Math.floor(room.cost / days); // Adding avg bedbase price in booking data
        bookingData.totalDiscount += room.vendorDiscount;

        const taxAmount =
          (room.cost * room.totalRooms - room.vendorDiscount) * 0.12;
        room.subTotal =
          room.cost * room.totalRooms - room.vendorDiscount + taxAmount;

        if (isCouponApplied) {
          room.couponAmount = parseFloat(
            (
              (room.subTotal / (this.state.price + couponValue)) *
              couponValue
            ).toFixed(2)
          );
          room.subTotal = parseFloat(
            (room.subTotal - room.couponAmount).toFixed(2)
          );
        }
      });
      const realAmount =
        this.state.price * 100 * this.state.hostel[0]?.payable_percent * 0.01; //converting rupees into paise
      const amountInPaise = Math.round(realAmount); //removing extra decimals

      const pendingAmount = Math.round(this.state.price - amountInPaise / 100); //counting pending amount
      const paidAmountInRs = amountInPaise / 100;
      roomKeys.forEach((roomKeys) => {
        //calculating paid and pending amount
        const room = allBookedRooms[roomKeys];
        const paidAmount = (
          (room.subTotal / this.state.price) *
          paidAmountInRs
        ).toFixed(2);
        const pendingAmount = (room.subTotal - paidAmount).toFixed(2);
        room.paidAmount = parseFloat(paidAmount);
        room.pendingAmount = parseFloat(pendingAmount);
      });

      const options = {
        key: "rzp_live_dg5eWb4CKEBDvz",
        currency: "INR",
        amount: amountInPaise,
        name: "Hostel Co",
        description: `Payment for Hostel Booking`,
        handler: function async(response) {
          console.log(response);
          if (!isOTPValid) {
            return;
          }
          const data = {
            firstName,
            middleName,
            lastName,
            phoneNumber,
            paidAmount: amountInPaise / 100,
            pendingAmount: pendingAmount,
            email,
            bookingData,
            razorpayId: response.razorpay_payment_id,
            couponCode,
            couponValue,
            nights: days,
          };
          const token = localStorage.getItem("token");
          const headers = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          axios
            .post(`${baseUrl}/reservations/booking`, data, headers)
            .then((data) => {
              console.log(data);

              if (isCouponApplied) {
                axios
                  .post(
                    `${baseUrl}/coupon/update-quantity`,
                    { couponCode, hostelId: bookingData.hostelId },
                    headers
                  )
                  .then((data) => {
                    console.log(data);
                  })
                  .catch((err) => {
                    console.log(err.message);
                  });
              }
              window.location.href = "/success";
            })
            .catch((err) => {
              console.log(err.message);
            });
        },
        prefill: {
          name: `${firstName} ${middleName} ${lastName}`,
          contact: phoneNumber,
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    };

    const loadScript = (src) => {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    };

    const sendOTP = () => {
      window.verifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {},
        }
      );
      const appVerifier = window.verifier;
      auth
        .signInWithPhoneNumber("+91" + this.state.phoneNumber, appVerifier)
        .then((confirmationResult) => {
          this.setState({
            result: confirmationResult,
            message: "OTP has been sent",
            messageColor: "success",
            isOtpSent: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ message: error.message, messageColor: "danger" });
        });
    };

    const checkCoupon = () => {
      const { couponCode } = this.state;
      const data = {
        couponCode,
        hostelId: JSON.parse(localStorage.getItem("bookingData")).hostelId,
      };
      const bookingData = JSON.parse(localStorage.getItem("bookingData"));
      axios
        .post(`${baseUrl}/coupon/validate`, data)
        .then((response) => {
          if (response.data.couponType === "value")
            this.setState({
              price: bookingData.totalAmount - response.data.couponValue,
              couponValue: response.data.couponValue,
              message: "Coupon applied",
              isCouponApplied: true,
              messageColor: "success",
            });
          else {
            this.setState({
              price:
                bookingData.totalAmount *
                (1 - response.data.couponValue * 0.01),
              couponValue:
                response.data.couponValue * 0.01 * bookingData.totalAmount,
              message: "Coupon applied",
              isCouponApplied: true,
              messageColor: "success",
            });
          }
          setTimeout(() => {
            this.setState({ message: "" });
          }, 3000);
        })
        .catch((err) => {
          this.setState({
            message: "Coupon not valid",
            messageColor: "danger",
            isCouponApplied: false,
            price: bookingData.totalAmount,
            couponValue: 0,
            couponCode:"",
          });
          setTimeout(() => {
            this.setState({ message: "" });
          }, 3000);
        });
    };

    const confirmOTP = () => {
      const { firstName, middleName, lastName, email, phoneNumber } =
        this.state;
      this.state.result
        .confirm(this.state.otp)
        .then((result) => {
          const data = {
            name: `${firstName} ${middleName} ${lastName}`,
            email: email,
            password: "Test@123",
            phoneNumber,
            role: "user",
          };
          axios
            .post(`${baseUrl}/auth/register`, data)
            .then((response) => {
              localStorage.setItem("token", response.data.token);
              this.setState({
                message: "Authenticated. Please proceed to pay",
                messageColor: "success",
                isOTPValid: true,
                isOtpSent: false,
              });
              setTimeout(() => {
                this.setState({ submitMessage: "" });
              }, 3000);
            })
            .catch((err) => {
              console.log(err.message);
            });
        })
        .catch((err) => {
          this.setState({
            submitMessage: "Please enter correct OTP",
            messageColor: "danger",
          });
          setTimeout(() => {
            this.setState({ submitMessage: "" });
          }, 3000);
        });
    };

    const disabledPay = !(
      this.state.firstName &&
      this.state.email &&
      this.state.phoneNumber &&
      this.state.is18 &&
      this.state.terms &&
      this.state.isOTPValid
    );

    return (
      <div className="bg-white-o">
        <div className="main-container">
          <div className="py-5 h2 fw-bold text-black">Secure your Booking</div>
          <div className="row row-cols-lg-2 row-cols-1 g-3 mb-5">
            <div className="col-12 col-lg-8">
              <Card className="mb-5 bg-secondary-o feature-card">
                <CardHeader className="bg-white d-flex gap-3 text-black align-items-center">
                  <i className="bi bi-person-circle fs-5"></i>{" "}
                  <div className="h5 mb-0">Customer Name</div>
                </CardHeader>
                <CardBody>
                  <div className="row row-cols-1 row-cols-lg-3 g-3">
                    <div className="col">
                      <Label className="text-black">
                        First Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        placeholder="First Name"
                        onChange={onChange}
                        value={this.state.firstName}
                        name="firstName"
                        className="bg-white text-primary-o"
                      />
                    </div>
                    <div className="col">
                      <Label className="text-black">Middle Name</Label>
                      <Input
                        placeholder="Middle Name"
                        onChange={onChange}
                        value={this.state.middleName}
                        name="middleName"
                        className="bg-white text-primary-o"
                      />
                    </div>
                    <div className="col">
                      <Label className="text-black">
                        Last Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        placeholder="Last Name"
                        onChange={onChange}
                        value={this.state.lastName}
                        name="lastName"
                        className="bg-white text-primary-o"
                      />
                    </div>
                    <div className="col">
                      <Label className="text-black">
                        Email <span className="text-danger">*</span>
                      </Label>
                      <Input
                        placeholder="Email"
                        onChange={onChange}
                        value={this.state.email}
                        name="email"
                        className="bg-white text-primary-o"
                      />
                    </div>
                    <div className="col">
                      <Label className="text-black">
                        Mobile Number <span className="text-danger">*</span>
                      </Label>
                      <InputGroup className="border rounded">
                        <InputGroupText className="bg-white text-dark border-0">
                          +91
                        </InputGroupText>
                        <Input
                          className="border-o bg-white text-primary-o"
                          placeholder="Phone Number"
                          onChange={onChange}
                          value={this.state.phoneNumber}
                          name="phoneNumber"
                        />
                      </InputGroup>
                    </div>
                    {!this.state.isOtpSent && (
                      <div className="col d-flex align-items-end">
                        <Button
                          onClick={sendOTP}
                          disabled={!this.state.phoneNumber}
                          className="bg-primary-o"
                        >
                          Send OTP
                        </Button>
                      </div>
                    )}
                    <div className="col">
                      <Label className="text-black">
                        <span
                          className="text-danger"
                          style={{ marginRight: "8px" }}
                        >
                          *
                        </span>
                      </Label>
                      <input
                        type="checkbox"
                        className="bg-white text-primary-o"
                        style={{ marginRight: "8px" }}
                        checked={this.state.is18}
                        onChange={this.handleCheckbox}
                        name="is18"
                      />
                      <Label className="text-black">
                        I confirm that I am 18+
                      </Label>
                    </div>
                    {this.state.isOtpSent && (
                      <div className="col">
                        <Label className="text-light-gray">Enter OTP</Label>
                        <Input
                          placeholder="OTP"
                          onChange={onChange}
                          value={this.state.otp}
                          name="otp"
                        />
                      </div>
                    )}
                    {this.state.isOtpSent && (
                      <div className="col d-flex align-items-end">
                        <Button
                          onClick={confirmOTP}
                          disabled={!this.state.phoneNumber}
                          className="bg-primary-o"
                        >
                          Confirm
                        </Button>
                      </div>
                    )}
                    {this.state.message && (
                      <Alert color={this.state.messageColor}>
                        {this.state.message}
                      </Alert>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div>
                <div className="h3 fw-bold text-black mb-3">
                  Terms and Conditions
                </div>
                <ol className="mb-5 text-black">
                  <li>
                    Booking once done is non transferable and non refundable.
                  </li>
                  <li>
                    Hostel own the right to cancel or modify the booking at
                    their discretion.
                  </li>
                  <li>
                    All guests are required to furnish Government ID at the time
                    of booking. With the Government ID details of the primary
                    guest(whose details are furnished at the point of booking)
                    matching the booking name
                  </li>
                  <li>
                    In case the property is not able to host you due to any
                    local or subjective circumstances, we will provide you with
                    a credit of the same amount to your bank account.
                  </li>
                  <li>
                    The Booking details will be sent via email to the email id,
                    please make sure you have access to the email ID shared here
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <Card className="feature-card border-o mb-3">
                <CardBody>
                  <div className="mb-3">
                    <img
                      src={this.state.hostel[0]?.thumbnail}
                      style={{ borderRadius: "1rem" }}
                      alt="hostelco"
                      className="w-100"
                    />
                  </div>
                  <div className="h4 text-black mb-2">
                    {this.state.hostel[0]?.hostel_name}
                  </div>
                  <div className="text-black mb-3">
                    {this.state.hostel[0]?.description}
                  </div>
                  <div className="d-flex gap-4 mb-3 text-black">
                    <div className="fw-bold">
                      <i className="bi bi-star-fill me-1"></i>{" "}
                      {this.state.hostel[0]?.rating}
                    </div>
                    <div>{this.state.hostel[0]?.total_reviews} reviews</div>
                  </div>
                  <div className="d-flex gap-3 justify-content-between text-black">
                    <div className="fw-bold">
                      <i className="bi bi-geo-alt me-1"></i>{" "}
                      {this.state.hostel[0]?.location.charAt(0).toUpperCase() +
                        this.state.hostel[0]?.location.slice(1).toLowerCase()}
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="mb-5 feature-card">
                <CardHeader className="bg-tertiary-o d-flex gap-3 text-white align-items-center">
                  <div className="h5 fw-bold mb-0">Price Details</div>
                </CardHeader>
                <CardBody>
                  {Object.keys(this.state.bookingData).length !== 0 && (
                    <div>
                      {Object.keys(this.state.bookingData["bookingData"]).map(
                        (roomType, index) => {
                          return (
                            <div key={index} className="text-black ">
                              <div className="fw-bold text-black mb-2">
                                {roomType}
                              </div>
                              <div className="d-flex justify-content-between mb-2 gap-5  ">
                                <div>
                                  <i className="bi bi-currency-rupee"></i>
                                  {
                                    this.state.bookingData["bookingData"][
                                      roomType
                                    ].cost
                                  }{" "}
                                  <i className="bi bi-x"></i>
                                  {
                                    this.state.bookingData["bookingData"][
                                      roomType
                                    ].totalRooms
                                  }
                                </div>
                                <div className="fw-bold ">
                                  <i className="bi bi-currency-rupee"></i>
                                  {this.state.bookingData["bookingData"][
                                    roomType
                                  ].cost *
                                    this.state.bookingData["bookingData"][
                                      roomType
                                    ].totalRooms}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between mb-2">
                                <div>Discount</div>
                                <div className="fw-bold">
                                  <i className="bi bi-currency-rupee"></i>
                                  {
                                    this.state.bookingData["bookingData"][
                                      roomType
                                    ].vendorDiscount
                                  }
                                </div>
                              </div>
                              <div className="d-flex justify-content-between mb-2">
                                <div>Tax.</div>
                                <div className="fw-bold">
                                  <i className="bi bi-currency-rupee"></i>
                                  {(
                                    (this.state.bookingData["bookingData"][
                                      roomType
                                    ].cost *
                                      this.state.bookingData["bookingData"][
                                        roomType
                                      ].totalRooms -
                                      this.state.bookingData["bookingData"][
                                        roomType
                                      ].vendorDiscount) *
                                    0.12
                                  ).toFixed(2)}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between mb-2">
                                <div className="fw-bold">Total</div>
                                <div className="fw-bold">
                                  <i className="bi bi-currency-rupee"></i>
                                  {(
                                    (this.state.bookingData["bookingData"][
                                      roomType
                                    ].cost *
                                      this.state.bookingData["bookingData"][
                                        roomType
                                      ].totalRooms -
                                      this.state.bookingData["bookingData"][
                                        roomType
                                      ].vendorDiscount) *
                                    1.12
                                  ).toFixed(2)}
                                </div>
                              </div>
                              <hr className="hr mb-3" />
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}

                  <div className="total-amount-div">
                    <h3 className="total-amount-heading"> Total Amount</h3>
                    <h6 className="total-amount">
                      {" "}
                      <i className="bi bi-currency-rupee"></i>{" "}
                      {Number(this.state.totalAmountOfRooms).toFixed(2)}
                    </h6>
                  </div>

                  {this.state.isCouponApplied && (
                    <div>
                      <div className="d-flex justify-content-between gap-3 mb-2 text-success">
                        <div>Coupon Applied</div>
                        <div className="fw-bold">
                          - Rs. {(this.state.couponValue).toFixed(2)}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between text-success">
                        <div className="fw-bold">Total after Coupon</div>
                        <div className="fw-bold">
                          <i className="bi bi-currency-rupee"></i>
                          {(this.state.price).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* <div className="d-flex justify-content-between gap-3 text-white">
                                        <div className="h5 mb-0">Total</div>
                                        <div className="fw-bold"><i className="bi bi-currency-rupee"></i> {this.state.price}</div>
                                    </div> */}
                  <div className="d-flex justify-content-between gap-3 text-black mb-3">
                    <div className="h5 mb-0">Payable Now</div>
                    <div className="fw-bold">
                      <i className="bi bi-currency-rupee"></i>{" "}
                      {(
                        (this.state.price *
                          this.state.hostel[0]?.payable_percent) /
                        100
                      ).toFixed(2)}
                    </div>
                  </div>

                  <Label className="fw-bold text-black">Coupon code</Label>
                  <div className="text-black mb-3">
                    Use a coupon, credit or promotional code
                  </div>
                  <div className="d-flex gap-2 align-items-center flex-wrap mb-2">
                    <Input
                      className="w-50 bg-white text-primary-o"
                      placeholder="Enter coupon code"
                      value={this.state.couponCode}
                      onChange={onChange}
                      name="couponCode"
                    />
                    <Button onClick={() => checkCoupon()} className="bg-dark-o">
                      Apply Coupon
                    </Button>
                  </div>
                  {this.state.message && (
                    <Alert color={this.state.messageColor}>
                      {this.state.message}
                    </Alert>
                  )}
                  <div className="d-flex gap-3 align-items-center mt-5 mb-2">
                    <FormGroup check>
                      <Input
                        type="checkbox"
                        checked={this.state.terms}
                        onChange={() =>
                          this.setState({ terms: !this.state.terms })
                        }
                      />
                      <Label className="fw-bold text-black" check>
                        I have read and understood all the terms and conditions.
                      </Label>
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      disabled={disabledPay}
                      onClick={onSubmit}
                      className="w-60"
                      color="success"
                      size="lg"
                    >
                      Pay Now
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
        <div id="recaptcha-container"></div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bookingData: state.booking.bookingData,
});

const mapDispatchToProps = {
  bookedHostel,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BookingPage)
);